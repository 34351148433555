<template>
  <v-sheet  class=" mb-3" >
    <div class="d-flex justify-space-between align-center">
      <h5 class="font-weight-medium">{{ label }}</h5>
      <div>
        <v-chip @click="selectedLang = lang"  v-for="(lang) in lang" :key="lang"
                class="mr-1 mt-2"
                :outlined="selectedLang !== lang"
                small
                :color="text[lang] ? wsDARK : 'grey'" dark >

          <h4>{{ lang }} </h4></v-chip>

        <v-menu offset-y transition="slide-y-transition">

          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on"
                   class="noCaps mt-2 px-0"
                   :color="wsDARK"
                   text  small >
          <span>
            <v-icon small>mdi-plus</v-icon>
            {{ $t('Add') }} </span>
            </v-btn>

          </template>
          <v-list dense>
            <v-list-item-group>
              <v-list-item @click="addTranslation(lang)" v-for="lang in remainedLanguages" :key="lang">
                <v-list-item-content>
                  <v-list-item-title>
                    {{ lang }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </div>
    </div>

    <v-sheet  outlined class="my-3 mt-5" >
        <wsEditorRegular v-model="text[selectedLang]" />
    </v-sheet>

  </v-sheet>

</template>

<script>
import wsEditorRegular from "@/components/UI/wsEditorRegular";

export default {
  name: "wsTranslationField",
  props : ['value','label','placeholder'],
  components : {
    wsEditorRegular,
  },
  computed : {
    langDefault() {
      return [process.env.VUE_APP_I18N_LOCALE, process.env.VUE_APP_I18N_FALLBACK_LOCALE]
    },
    lang() {
     return [...this.langDefault, ...this.langBase ]
    },
    remainedLanguages(){
      let items = this.PARAMS_languages
      items = items.filter( el=> !this.lang.includes(el) )
      return items
    }

  },
  watch: {
    value(val) {
      if ( val !== this.text ) {
        this.text = val
        let langs =  Object.keys(this.text)
        langs = langs.filter( el=> !this.langDefault.includes(el) )
        this.langBase = langs
      }
    }
  },
  data() {
    return {
      selectedLang : 'ua',
      text : { },
      langBase : []
    }
  },
  methods : {
    addTranslation(lang) {
      this.langBase.push(lang)
    },
  },
  mounted() {
    if( this.value ) { this.text = this.value }
    if ( this.value && Object.prototype.toString.call(this.value) === '[object Object]')  {
      Object.keys(this.value).forEach((key)=>{
        if (!this.lang.includes(key)) {
          this.langBase.push(key)
        }
      })
    }
  }
}
</script>

<style scoped>

</style>