<template>
  <dash-page :title="'Оновлення ' + entity.date"
             :header-route="localeLink('dashboard/updates')">

    <template #header_action>
      <v-btn @click="editUpdateDescription" class="noCaps mr-3" :color="wsATTENTION" dark> {{ $t('Save') }} </v-btn>
    </template>

    <template #default>
      <v-sheet class="pa-6 mt-6 wsRoundedHalf">
        <wsTranslationText v-model="entity.content" />
      </v-sheet>
    </template>

    <template #side>
      <v-btn v-if="!entity.is_published" @click="publishUpdateDescription" class="noCaps mr-3" :color="wsATTENTION" dark> {{ $t('Publish') }} </v-btn>
    </template>

  </dash-page>
</template>

<script>
import {mapActions} from "vuex";
import wsTranslationText from "@/components/UI/wsTranslationText";

export default {
  name: "editUpdateDescription",
  props : [ 'uuid' ],
  components : {
    wsTranslationText
  },
  data() {
    return {
      entity : {}
    }
  },
  methods : {
    ...mapActions('adminCrmSystem', [
        'GET_UPDATE_DESCRIPTION',
        'EDIT_UPDATE_DESCRIPTION',
        'PUBLISH_UPDATE_DESCRIPTION'
    ]),

    async editUpdateDescription() {
      let result = await this.EDIT_UPDATE_DESCRIPTION(this.entity)
      if ( !result ) { return }
      this.notify(this.$t('ChangesSaved'))
    },
    async publishUpdateDescription() {
      let result = await this.PUBLISH_UPDATE_DESCRIPTION(this.entity)
      if ( !result ) { return }
      this.entity.is_published = true
      this.notify('Опис оновлення опубліковано')
    },
  },
  async mounted() {
    let result = await this.GET_UPDATE_DESCRIPTION(this.uuid)
    if ( !result || result === true) {
      return
    }
    this.entity = result
  }
}
</script>

<style scoped>

</style>